.blog-post-card-blog-post-card {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 400px;
  align-self: stretch;
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
}
.blog-post-card-blog-post-card:hover {
  transform: scale(1.02);
}
.blog-post-card-image {
  flex: 1;
  width: 100%;
  object-fit: cover;
}
.blog-post-card-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.blog-post-card-text {
  color: var(--dl-color-gray-black80);
  font-size: 22px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  line-height: 28px;
  margin-bottom: var(--dl-space-space-unit);
}
.blog-post-card-text1 {
  color: var(--dl-color-gray-black50);
  font-size: 16px;
  font-style: normal;
  margin-bottom: var(--dl-space-space-twounits);
}

@media(max-width: 991px) {
  .blog-post-card-blog-post-card {
    max-width: 48%;
  }
}
@media(max-width: 767px) {
  .blog-post-card-blog-post-card {
    max-width: 100%;
  }
}
